
import Response from "@/sg_copy/model/Response";
import {tooltipMixin} from "@/sg_copy/mixins/TooltipMixin";
import AppStatus from "@/sg_copy/components/AppStatus.vue";
import Vue, {PropType} from 'vue';

export default Vue.extend({
  name: 'ResponseSummaryCard',
  components: {AppStatus},
  mixins: [tooltipMixin],
  props: {
    response: {
      type: Object as PropType<Response>,
      required: false,
    },
  },

  methods: {
    openResponse() {
      this.emitOpenResponse();
      this.$nextTick(() => {
        window.open(this.response.responseLink, "_blank");
      });
    },
    emitOpenResponse() {
      this.$emit('open-response');
    },
  },
});
