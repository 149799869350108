
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import GrantApp from "@/sg_copy/model/GrantApp";
import {
  ApplicantOrgDto,
  GrantAppDetailDto,
  SfPermission,
} from "@/sg_copy/swagger-generated";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import { apiErrorUtil } from "@/sg_copy/utils/ApiErrorUtil";
import Multiselect from "vue-multiselect";
import { permissionService } from "@/sg_copy/service/PermissionService";
import handleApiError from "@/shared/apiErrorUtil";

const ALL_PERMISSIONS = [
  SfPermission.GRANT_APP_CREATE,
  SfPermission.GRANT_APP_WRITE,
]

export default defineComponent({
  name: 'AssignOrgModal',

  components: {
    Multiselect,
    ErrorMessageCard,
  },

  props: {
    app: {
      type: Object as () => GrantApp,
      required: true
    }
  },

  data() {
    return {
      appDetail: null as GrantAppDetailDto,
      orgList: [] as Array<ApplicantOrgDto>,
      error: false,
      selectedPermissions: [] as Array<SfPermission>,
      orgSelected: null as any,
      options: [] as Array<any>,
      loading: true,
      validation: null as string,
      localApp: null as GrantApp
    };
  },

  computed: {
    permissionOptions() {
      return ALL_PERMISSIONS.map(permission => ({
        value: permission,
        text: permissionService.getDescriptionFor(permission)
      }));
    }
  },

  watch: {
    app: {
      immediate: true,
      handler(newApp: GrantApp) {
        this.localApp = newApp;
      }
    },
    orgSelected(val: any) {
      if (val && val.id !== "0") {
        this.validation = null;
      }
    },
    selectedPermissions() {
      this.updateOptionFromList();
    }
  },

  created() {
    if (this.localApp) {
      this.load();
    }
  },

  methods: {
    ...mapActions('mySubmissionStore', [
      'getApp',
      'getUser',
      'assignOrg'
    ]),
    onOrgSelected(val: any) {
      if (val && val.id !== "0") {
        this.validation = null;
      }
    },

    load() {
      this.loading = true;
      this.error = false;
      this.appDetail = null;
      this.validation = null;
      this.selectedPermissions = ALL_PERMISSIONS;

      const promiseApp = this.getApp(this.localApp!.id)
          .then(response => (this.appDetail = response.data))
          .catch(() => (this.error = true));

      const promiseUser = this.getUser()
          .then(response => {
            this.orgList = response.data.applicantOrgs.filter(role =>
                ALL_PERMISSIONS.some(
                    p => role.permissions.indexOf(p) >= 0
                )
            );
          })
          .catch(() => (this.error = true));

      Promise.all([promiseApp, promiseUser]).then(() => {
        if (!this.appDetail || !this.orgList.length) {
          this.error = true;
          this.$bvModal.hide("assign-org-modal");
          const title = "Unable to load application detail";
          apiErrorUtil.showApiError(this.localApp, title);
        } else {
          this.loading = false;
        }

        if (!this.loading) {
          this.orgSelected = null;
          this.updateOptionFromList();
        }
      });
    },

    updateOptionFromList() {
      if (!this.orgList) {
        return;
      }

      let permissions = this.selectedPermissions;
      if (permissions.length === 0) {
        permissions = ALL_PERMISSIONS;
      }

      this.options = [];
      let orgInList = false;
      for (const role of this.orgList) {
        if (permissionService.hasPermissions(role, permissions)) {
          this.options.push({
            text: role.orgName,
            id: role.orgId,
          });
          if (this.orgSelected != null && role.orgId === this.orgSelected.id) {
            orgInList = true;
          }
        }
      }
      if (!orgInList) {
        this.orgSelected = null;
      }
    },

    assignAppToOrg(bvModalEvt: Event) {
      bvModalEvt.preventDefault();
      if (this.localApp) {
        this.localApp.processing = true;
      }

      if (this.orgSelected === null || this.orgSelected.id === "0") {
        this.validation = "Please select an organisation.";
        if (this.localApp) {
          this.localApp.processing = false;
        }
        return;
      }

      const org = {
        orgId: this.orgSelected.id,
        orgName: this.options.filter(value => value.id === this.orgSelected.id)[0]
            .text,
      };

      const data = {
        appId: this.localApp!.id,
        orgId: org.orgId,
      };

      this.assignOrg(data)
          .then(() => {
            this.$emit('org-changed');
            if (this.localApp) {
              this.localApp.processing = true;
            }
            this.$bvModal.hide("assign-org-modal");

            const message =
                "Application " + this.localApp!.header + " assigned to org " + org.orgName;
            this.$bvToast.toast(message, {
              title: "Success",
              autoHideDelay: 5000,
              variant: "success",
              solid: true,
            });
          })
          .catch(error => {
            if (this.localApp) {
              this.localApp.processing = false;
            }
            this.error = true;
            handleApiError(
                error,
                this,
                "Error assigning org for application " + this.localApp!.header
            );
          });
    }
  }
});
