
import Vue from "vue";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import Response from "@/sg_copy/model/Response";
import { GrantAppTinyDto } from "@/sg_copy/swagger-generated";
import {mapActions} from "vuex";

export default Vue.extend({
  name: "CreateVariationModal",

  components: {
    ErrorMessageCard,
  },

  props: {
    app: {
      type: Object as () => GrantAppTinyDto,
      required: true
    }
  },

  data() {
    return {
      loading: true,
      error: false,
      processing: false,
      variationExistsError: false,
    };
  },

  created() {
    if (this.app) {
      this.load();
    }
  },

  methods: {
    ...mapActions('mySubmissionStore', ['startVariation']),
    load() {
      this.loading = true;
      this.error = false;
      this.processing = false;
      this.loading = false;
    },

    createVariationForm(bvModalEvent) {
      this.processing = true;
      bvModalEvent.preventDefault(); // prevent closing on ok - success will redirect

      this.startVariation(this.app.id)
          .then(responseDto => {
            if (responseDto.data) {
              this.emitVariationCreated();
              const response = new Response(responseDto.data, null);
              window.open(response.responseLink, "_blank");
              this.$bvModal.hide("create-variation-modal");
            }
          })
          .catch(error => {
            if (
                error.response &&
                error.response.data &&
                error.response.data.variationExistsError
            ) {
              this.variationExistsError = true;
            }
            this.error = true;
            this.processing = false;
          });
    },

    emitVariationCreated() {
      this.$emit("variation-created");
    }
  }
});
