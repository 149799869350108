
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import GrantApp from '@/sg_copy/model/GrantApp';
import {
  ApplicantOrgMemberDto,
  GrantAppDetailDto,
  SfPermission,
} from '@/sg_copy/swagger-generated';
import ErrorMessageCard from '@/sg_copy/components/ErrorMessageCard.vue';
import Multiselect from 'vue-multiselect';
import handleApiError from '@/shared/apiErrorUtil';
import { permissionService } from '../service/PermissionService';

export default defineComponent({
  name: 'ChangeUserModal',

  components: {
    ErrorMessageCard,
    Multiselect,
  },

  props: {
    app: {
      type: Object as () => GrantApp,
      required: true,
    },
  },

  data() {
    return {
      appDetail: null as GrantAppDetailDto,
      userList: null as Array<ApplicantOrgMemberDto>,
      error: false,
      selected: [] as Array<SfPermission>,
      usernameSelected: null as any,
      options: [] as Array<any>,
      loading: true,
      validation: null as string,
      isProcessing: false, // New local variable to track processing state
    };
  },

  computed: {
    ALL_PERMISSIONS(): Array<SfPermission> {
      return [
        SfPermission.GRANT_APP_CREATE,
        SfPermission.GRANT_APP_WRITE,
        SfPermission.GRANT_APP_READ,
        SfPermission.GRANT_APP_SUBMIT,
      ];
    },

    permissionOptions() {
      return this.ALL_PERMISSIONS.map(permission => ({
        value: permission,
        text: permissionService.getDescriptionFor(permission),
      }));
    },
  },

  watch: {
    usernameSelected: {
      handler(val: any) {
        this.onUsernameSelected(val)
      },
    },
    selected: {
      handler() {
        this.updateOptionFromList();
      },
    },
  },

  created() {
    if (this.app) {
      this.load();
    }
  },

  methods: {
    ...mapActions('mySubmissionStore', [
      'getApp',
      'changeUser',
      'getMembers',
    ]),
    onUsernameSelected(val: any) {
      if (val && val.id !== '0') {
        this.validation = null;
      }
    },

    async load() {
      this.loading = true;
      this.error = false;
      this.appDetail = null;
      this.validation = null;
      this.selected = this.ALL_PERMISSIONS;

      try {
        const [appResponse, membersResponse] = await Promise.all([
          this.getApp(this.app.id),
          this.getMembers({
            appId: this.app.id,
            operation: 'change',
          }),
        ]);

        this.appDetail = appResponse.data;
        this.userList = membersResponse.data.data;

        if (!this.appDetail || !this.userList) {
          throw new Error('Failed to load data');
        }

        this.loading = false;
        this.usernameSelected = null;
        this.updateOptionFromList();
      } catch (error) {
        this.error = true;
        this.$bvModal.hide('change-user-modal');
        handleApiError(
            'Unable to load application detail',
            this,
            'Unable to load application detail'
        );
      }
    },

    updateOptionFromList() {
      if (!this.userList) {
        return;
      }

      let permissions = this.selected;
      if (permissions.length === 0) {
        permissions = this.ALL_PERMISSIONS;
      }

      this.options = [];
      let userInList = false;

      for (const role of this.userList) {
        if (permissionService.hasPermissions(role, permissions)) {
          const userId = role.user.id.toString();
          this.options.push({
            text: role.user.name,
            id: userId,
          });

          if (userId === this.usernameSelected?.id) {
            userInList = true;
          }
        }
      }

      if (!userInList) {
        this.usernameSelected = null;
      }
    },

    async changeApplicationUser(bvModalEvt: Event) {
      bvModalEvt.preventDefault();
      this.isProcessing = true; // Use local state instead of prop mutation

      if (this.usernameSelected === null || this.usernameSelected.id === '0') {
        this.validation = 'Please select a user.';
        this.isProcessing = false;
        return;
      }

      if (this.usernameSelected.id !== this.appDetail?.userId.toString()) {
        try {
          await this.changeUser({
            appId: this.app.id,
            userId: {
              id: parseInt(this.usernameSelected.id),
            },
          });

          this.isProcessing = false;
          this.$emit('user-changed');
          this.$bvModal.hide('change-user-modal');

          const newUserName = this.options.find(
              value => value.id === this.usernameSelected.id
          )?.text;

          this.$bvToast.toast(
              `Application ${this.app.header} user changed to ${newUserName}`,
              {
                title: 'Success',
                autoHideDelay: 5000,
                variant: 'success',
                solid: true,
              }
          );
        } catch (error) {
          this.isProcessing = false;
          this.error = true;
          handleApiError(
              error,
              this,
              `Error changing user for application ${this.app.header}`
          );
        }
      } else {
        this.isProcessing = false;
        this.$nextTick(() => {
          this.$bvModal.hide('change-user-modal');
        });
      }
    },
  },
});
