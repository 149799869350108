

import type Response from '@/sg_copy/model/Response'
import Vue from 'vue';
import { mapActions } from 'vuex';
import FileSaver from 'file-saver';
import handleApiError from '@/shared/apiErrorUtil';

export default Vue.extend({
  name: 'ResponseCard',

  props: {
    response: {
      type: Object as () => Response,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false
    };
  },

  methods: {
    ...mapActions('mySubmissionStore', ['downloadPdf']),

    callDownloadPdfApi(): void {
      this.loading = true;
      this.downloadPdf(this.response.responseId)
          .then((response: any) => {
            FileSaver.saveAs(
                new Blob([response.data], { type: 'application/pdf' }),
                `${this.applicationId} - ${this.response.formName}.pdf`
            );
            this.loading = false;
          })
          .catch((error: Error) => {
            handleApiError(error, this);
            this.loading = false;
          });
    }
  }
});
