
import Vue from 'vue';
import { mapActions } from 'vuex';
import FilterTags from "@/sg_copy/components/FilterTags.vue";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import { SimpleIdNameDto, UserDto } from "@/sg_copy/swagger-generated";

export default Vue.extend({
  name: 'AdvancedSearch',

  components: {
    FilterTags
  },

  props: {
    filter: {
      type: Object as () => SubmissionFilter,
      required: true
    },
    user: {
      type: Object as () => UserDto,
      required: true
    }
  },

  data() {
    return {
      localFilter: this.filter as SubmissionFilter,
      classieOrgTypeOptions: [] as SimpleIdNameDto[],
      regionOptions: [] as string[],
      statusOptions: [
        { value: 1, text: "Submitted" },
        { value: 0, text: "In progress" }
      ] as Array<{ value: number; text: string }>
    }
  },

  methods: {
    ...mapActions('mySubmissionStore', [
      'getClassieOrgTypes',
      'getRegions'
    ]),

    clearAdvancedSearch(): void {
      this.localFilter.clearAdvancedSearch();
    },

    onDisplaySimpleSearch(filter: SubmissionFilter): void {
      this.localFilter = filter;
      this.displaySimpleSearch();
    },

    displaySummarisedSearch(): void {
      this.localFilter.showSummarisedSearch = true;
      this.localFilter.showAdvancedSearch = false;
      this.localFilter.showSimpleSearch = false;
      this.saveFilterRefresh();
    },

    onFilterTagsChange(filter: SubmissionFilter): void {
      this.localFilter = filter;
      this.saveFilterRefresh();
    },

    saveFilterRefresh(): void {
      this.$emit('advanced-search-refresh', this.localFilter);
    },

    displaySimpleSearch(): void {
      this.$emit('display-simple-search', this.localFilter);
    },

    async initializeData(): Promise<void> {
      try {
        const [classieResponse, regionsResponse] = await Promise.all([
          this.getClassieOrgTypes(),
          this.getRegions()
        ]);

        this.classieOrgTypeOptions = classieResponse.data;
        this.regionOptions = regionsResponse.data;
      } catch (error) {
        console.error('Error initializing data:', error);
      }
    }
  },

  mounted(): void {
    this.initializeData();
  },

  watch: {
    filter: {
      immediate: true,
      handler(newFilter: SubmissionFilter): void {
        this.localFilter = newFilter;
      },
      deep: true
    }
  }
});
