
import Vue from 'vue';
import { mapActions } from 'vuex';
import GrantApp from "@/sg_copy/model/GrantApp";
import handleApiError from "@/shared/apiErrorUtil";
import { AppDropdownAction } from "@/sg_copy/model/AppDropdownAction";

export default Vue.extend({
  name: 'AppDropdownMenu',

  props: {
    app: {
      type: Object as () => GrantApp,
      required: true
    }
  },

  data() {
    return {
      localApp: this.app as GrantApp
    };
  },

  computed: {
    hasUpperElements(): boolean {
      return this.archiveAllowed || this.unarchiveAllowed || this.deleteAllowed;
    },

    hasLowerElements(): boolean {
      return (
          this.shareUserAllowed ||
          this.changeUserAllowed ||
          this.assignOrgAllowed ||
          this.canRemoveOrg
      );
    },

    shouldDisableDropDown(): boolean {
      return (
          this.localApp.processing ||
          !(this.hasUpperElements || this.hasLowerElements)
      );
    },

    showDivider(): boolean {
      return this.hasUpperElements && this.hasLowerElements;
    },

    deleteAllowed(): boolean {
      return this.localApp.userPermission && this.localApp.userPermission.deleteAllowed;
    },

    archiveAllowed(): boolean {
      return !this.localApp.archived && this.localApp.userPermission?.archiveAllowed;
    },

    unarchiveAllowed(): boolean {
      return this.localApp.archived && this.localApp.userPermission?.archiveAllowed;
    },

    shareUserAllowed(): boolean {
      return !this.localApp.archived && this.localApp.userPermission?.shareUserAllowed;
    },

    changeUserAllowed(): boolean {
      return !this.localApp.archived && this.localApp.userPermission?.changeUserAllowed;
    },

    assignOrgAllowed(): boolean {
      return !this.localApp.archived && this.localApp.userPermission?.assignToOrgAllowed;
    },

    canRemoveOrg(): boolean {
      return this.localApp.userPermission?.removeOrgAllowed;
    }
  },

  watch: {
    app: {
      immediate: true,
      handler(newGrantApp: GrantApp) {
        this.localApp = newGrantApp;
      },
    }
  },

  methods: {
    ...mapActions('mySubmissionStore', [
      'deleteApp',
      'archiveApp',
      'removeOrg'
    ]),

    archiveApplication(): void {
      if (this.localApp.userPermission.archiveAllowed) {
        this.localApp.processing = true;

        const data = {
          appId: this.localApp.id,
          archive: true,
        };

        this.archiveApp(data)
            .then(() => this.localApp.remove())
            .catch(error => {
              console.log("Error: " + error);
              handleApiError(
                  error,
                  this,
                  "Error archiving application " + this.localApp.header
              );
            })
            .finally(() => (this.localApp.processing = false));
      }
    },

    unarchiveApplication(): void {
      if (this.localApp.userPermission.archiveAllowed) {
        this.localApp.processing = true;
        const data = {
          appId: this.localApp.id,
          archive: false,
        };
        this.archiveApp(data)
            .then(() => this.localApp.remove())
            .catch(error => {
              console.log("Error: " + error);
              handleApiError(
                  error,
                  this,
                  "Error reactivating application " + this.localApp.header
              );
            })
            .finally(() => (this.localApp.processing = false));
      }
    },

    deleteApplication(): void {
      console.log('test', this.localApp.userPermission.deleteAllowed)
      if (this.localApp.userPermission.deleteAllowed) {
        const h = this.$createElement;
        const messageVNode = h("div", [
          h(
              "strong",
              "Are you sure you want to delete Application No. " +
              this.localApp.header +
              "?"
          ),
          h("br"),
          h(
              "em",
              "The application will be permanently deleted. You can't undo this action."
          ),
        ]);

        this.$bvModal
            .msgBoxConfirm([messageVNode], {
              title: "Delete Submission",
              okTitle: "Delete",
              okVariant: "danger",
            })
            .then(response => {
              if (response) {
                this.callDeleteApplication();
              }
            });
      }
    },

    callDeleteApplication(): void {
      this.localApp.processing = true;
      this.deleteApp(this.localApp.id)
          .then(() => {
            this.localApp.remove();

            const message = "Application " + this.localApp.header + " has been deleted";
            this.$bvToast.toast(message, {
              title: "Success",
              variant: "success",
              solid: true,
            });
          })
          .catch(error => {
            handleApiError(
                error,
                this,
                "Error deleting application " + this.localApp.header
            );
          })
          .finally(() => (this.localApp.processing = false));
    },

    share(): void {
      this.emitAction(this.localApp, "share");
    },

    emitAction(app: GrantApp, action: AppDropdownAction): void {
      this.$emit('action', app, action);
    },

    changeUser(): void {
      this.emitAction(this.localApp, "change");
    },

    assignOrg(): void {
      this.emitAction(this.localApp, "assignOrg");
    }
  }
});
