import {Module, Mutation, VuexModule} from "vuex-module-decorators";

export interface NotificationModel {
  title: string;
  message: string;
  variant?: string;
}

export interface ConfirmationModel extends NotificationModel {
  okButtonText?: string;
  cancelButtonText?: string;
  showCancelButton?: boolean;
  size?: string;

  action(): void;
}

@Module({namespaced: true, name: "NotificationStore"})
class NotificationStore extends VuexModule {
  private notification: NotificationModel = null;

  private confirmationVisibility = false;

  private apiErrorVisibility = false;

  private apiError: NotificationModel = new (class
    implements NotificationModel
  {
    message = "Default message";
    title = "Default title";
  })();

  private confirmation: ConfirmationModel = new (class
    implements ConfirmationModel
  {
    message = "Default message";
    title = "Default title";
    action;
  })();

  @Mutation
  public hideConfirmation(): void {
    this.confirmationVisibility = false;
  }

  @Mutation
  public hideApiError(): void {
    this.apiErrorVisibility = false;
  }

  @Mutation
  public showConfirmation(confirmationModel: ConfirmationModel): void {
    this.confirmation = confirmationModel;
    this.confirmationVisibility = true;
  }

  @Mutation
  public showApiError(apiError: NotificationModel): void {
    this.apiError = apiError;
    this.apiErrorVisibility = true;
  }

  @Mutation
  public notify(notificationModel: NotificationModel): void {
    this.notification = notificationModel;
  }
}

export default NotificationStore;
