
import ApiErrorUtil from "@/sg_copy/utils/ApiErrorUtil";
import Vue from 'vue';
export default Vue.extend({
  name: 'ErrorMessage',
  props: {
    variationMessage: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      apiErrorUtil: ApiErrorUtil.getInstance() as ApiErrorUtil,
    };
  },
});
