
import Vue from 'vue';
import GrantApp from "@/sg_copy/model/GrantApp";
import AppDropdownMenu from "@/sg_copy/components/AppDropdownMenu.vue";
import AppSubmissionExpandedCard from "@/sg_copy/components/AppSubmissionExpandedCard.vue";
import {tooltipMixin} from "@/sg_copy/mixins/TooltipMixin";
import AppStatus from "@/sg_copy/components/AppStatus.vue";
import {AppDropdownAction} from "@/sg_copy/model/AppDropdownAction";
import {GrantAppTinyDto} from "@/sg_copy/swagger-generated";

export default Vue.extend({
  name: 'AppSubmissionCard',

  mixins: [tooltipMixin],

  components: {
    AppStatus,
    AppDropdownMenu,
    AppSubmissionExpandedCard,
  },

  props: {
    app: {
      type: Object as () => GrantApp,
      required: true
    }
  },

  data() {
    return {
      localApp: this.app as GrantApp
    };
  },

  computed: {
    status(): string {
      return this.localApp.subsequentStatus.toString();
    }
  },

  watch: {
    app: {
      immediate: true,
      handler(newGrantApp: GrantApp) {
        if (newGrantApp) {
          this.localApp = newGrantApp;
        }
      },
    }
  },

  methods: {
    showAppDetail(): void {
      this.$emit('app-detail', this.localApp);
    },

    emitFocusedApp(appId: number): void {
      this.$emit('focused-app', appId);
    },

    dropDownAction(app: GrantApp, action: AppDropdownAction): void {
      this.$emit('dropdown-action', app, action);
    },

    emitShowVariationModal(app: GrantApp): void {
      this.$emit('show-variation-modal', app);
    },

    passShowVariationModal(app: GrantAppTinyDto): void {
      this.$emit('show-variation-modal', app);
    },
  },
});
