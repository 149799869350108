
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import { defineComponent } from "vue";
import SimpleSearch from "@/sg_copy/components/SimpleSearch.vue";
import SubmissionsSort from "@/sg_copy/components/SubmissionsSort.vue";
import AdvancedSearch from "@/sg_copy/components/AdvancedSearch.vue";

export default defineComponent({
  name: 'SubmissionsSearch',
  components: {
    AdvancedSearch,
    SubmissionsSort,
    SimpleSearch,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filter: new SubmissionFilter(),
    };
  },
  methods: {
    displayAdvancedSearch() {
      this.filter.showSummarisedSearch = false;
      this.filter.showAdvancedSearch = true;
      this.filter.showSimpleSearch = false;
      this.filter.clearSimpleSearch();
    },
    displaySimpleSearch() {
      this.filter.showSimpleSearch = true;
      this.filter.showAdvancedSearch = false;
      this.filter.showSummarisedSearch = false;
      this.clearAdvancedSearch();
      this.saveFilterRefresh();
    },
    saveFilterRefresh() {
      this.refresh(this.filter);
    },
    refresh(filter: SubmissionFilter) {
      this.$emit("refresh", filter);
    },
    onFilterChange(filter: SubmissionFilter) {
      this.filter = filter;
      this.saveFilterRefresh();
    },
    onDisplaySimpleSearch(filter: SubmissionFilter) {
      this.filter = filter;
      this.displaySimpleSearch();
    },
    clearAdvancedSearch() {
      this.filter.clearAdvancedSearch();
    },
  },
});
