
import { defineComponent, PropType } from "vue";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";


export default defineComponent({
  props: {
    filter: {
      type: Object as PropType<SubmissionFilter>,
      required: true,
    },
  },
  data() {
    return {
      localFilter: null as SubmissionFilter | null
    }
  },
  methods: {
    saveFilterRefresh() {
      this.refresh(this.localFilter);
    },
    refresh (filter: SubmissionFilter) {
      this.$emit("simple-search-refresh", filter);
    }
  },
  watch: {
    filter: {
      immediate: true,
      handler(newFilter: SubmissionFilter) {
        if (newFilter) {
          this.localFilter = newFilter;
        }
      },
    },
  }
});
