
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import { defineComponent } from "vue";

export default defineComponent({
  name: 'SubmissionsSort',
  props: {
    filter: {
      type: Object as () => SubmissionFilter,
      required: true,
    },
  },
  data() {
    return {
      localFilter: { ...this.filter }, // Local copy of filter to avoid direct prop mutation
      sortOptions: [
        { value: "INSTANCE_NAME", text: "Funder" },
        { value: "PUBLIC_ID", text: "Application No" },
        { value: "GRANT_ROUND", text: "Round" },
        { value: "PROJECT_TITLE", text: "Project Title" },
        { value: "DUE_DATE", text: "Due Date" },
        { value: "SUBMITTED_DATE", text: "Submitted Date" },
        { value: "STATUS", text: "Status" },
      ],
    };
  },
  watch: {
    localFilter: {
      deep: true,
      handler() {
        this.saveFilterRefresh();
      },
    },
  },
  methods: {
    toggleSortDirection() {
      this.localFilter.sortAscending = !this.localFilter.sortAscending;
    },
    saveFilterRefresh() {
      this.$emit("sort-refresh", this.localFilter);
    },
  },
});
