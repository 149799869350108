
import moment from "moment-timezone";
import {
  FormDto,
  GrantAppDetailDto,
  GrantAppTinyDto,
  ResponseDto,
  ResponseDtoStatusEnum,
} from "@/sg_copy/swagger-generated";
import AppStatus from "@/sg_copy/components/AppStatus.vue";
import ChildAppDropdownMenu from "@/sg_copy/components/ChildAppDropdownMenu.vue";
import GrantApp from "@/sg_copy/model/GrantApp";
import {canDelete} from "@/sg_copy/model/GrantAppDetail";
import {defineComponent, PropType} from "vue";

const DEFAULT_TIME_ZONE_ID = "Australia/Sydney";

export default defineComponent({
  name: "NestedForms",
  components: {
    AppStatus,
    ChildAppDropdownMenu,
  },
  props: {
    mainApp: GrantApp,
    appDetail: {
      type: Object as PropType<GrantAppDetailDto>,
      required: true,
    },
  },
  emits: ["show-variation-modal"],
  computed: {
    appsByForm() {
      const map = new Map<number, GrantAppDetailDto[]>();
      for (let form of this.appDetail.childForms) {
        map.set(form.id, []);
      }
      for (let app of this.appDetail.childApps) {
        const apps = map.get(app.roundFormId);
        if (apps && !app.removed) {
          // check undefined as there could be childApps that are no longer in childForms
          if (map.get(app.roundFormId) !== undefined) {
            apps.push(app);
          }
        }
      }
      return map;
    },
    baseUrl() {
      return `https://${this.appDetail.sitePublicUrl}/`;
    },
  },
  methods: {
    hasNestedForms(app: GrantAppDetailDto): boolean {
      return !app.removed && app.childForms.length > 0;
    },
    hasSiblingForms(app: GrantAppDetailDto): boolean {
      return app.responses.length > 1;
    },
    shouldShowVariationLink(app: GrantAppDetailDto): boolean {
      return app && !app.removed && app.displayStartVariationLink;
    },
    siblingResponses(app: GrantAppDetailDto): ResponseDto[] {
      // There is no link in the raw data between the responses and actual forms,
      // so the main form will be assumed to be the one with the lowest response
      // Id, since none of the subsequent forms/responses can be created before
      // the first response is created.
      const respIds = app.responses.map(r => r.responseId);
      const firstResponseId = Math.min(...respIds);
      return app.responses.filter(r => r.responseId !== firstResponseId);
    },

    linkToStart(form: FormDto): string {
      return `${this.baseUrl}${form.id}/${this.appDetail.applicationId}/startlinked`;
    },

    linkToContinue(app: GrantAppDetailDto): string {
      return this.linkResponse(app.responses[0]);
    },

    linkResponse(resp: ResponseDto): string {
      return `${this.baseUrl}form/${resp.responseId}/continue`;
    },
    status(app: GrantAppDetailDto): string {
      const status = app.responses[0]
        ? app.responses[0].status
        : ResponseDtoStatusEnum.NOT_STARTED;
      return status.toString();
    },
    responseStatus(resp: ResponseDto): string {
      return resp?.status || ResponseDtoStatusEnum.NOT_STARTED;
    },
    getSubmittedDate(app: GrantAppDetailDto): string {
      if (app.responses[0]) {
        return this.responseSubmittedDate(app.responses[0]);
      }
      return "";
    },

    responseSubmittedDate(resp: ResponseDto): string {
      if (resp.submitDate) {
        const date = moment(resp.submitDate)
          .tz(DEFAULT_TIME_ZONE_ID)
          .format("DD MMM YYYY");
        return ` (${date})`;
      }
      return "";
    },

    titleId(app: GrantAppDetailDto): string {
      return "project-title-" + app.applicationId;
    },

    getPublicVariationId(response: ResponseDto): string {
      return response.variationPublicId && response.variationPublicId !== ""
        ? response.variationPublicId + " - "
        : "";
    },

    childAppDeleteHandler(parentApp: GrantAppDetailDto): void {
      // update delete permissions of the parent application
      if (canDelete(parentApp)) {
        parentApp.userPermission.deleteAllowed = true;
      }
    },

    showVariationModal(app: GrantAppDetailDto) {
      // This is the handler to generate the original event
      const value = {
        id: app.applicationId,
        header: app.application,
      };
      this.$emit("show-variation-modal", value);
    },

    passShowVariationModal(app: GrantAppTinyDto) {
      // This is the handler to bubble up from child nested forms
      this.$emit("show-variation-modal", app);
    },
  },
});
