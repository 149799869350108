
import Submissions from "@/sg_copy/components/Submissions.vue";
import { UserDto } from "@/sg_copy/swagger-generated";
import { mapState, mapActions, mapGetters } from "vuex";
import handleApiError from "@/shared/apiErrorUtil";

export default {
  components: {
    Submissions,
  },
  data() {
    return {
      user: null as UserDto | null,
    };
  },
  computed: {
    ...mapState('orgStore', {
      orgs: state => state.orgs,
    }),
    ...mapGetters('auth', ['getCurrentUser']),
  },
  methods: {
    ...mapActions('orgStore', ['fetchOrgs']),
    ...mapActions('mySubmissionStore', ['getUserInstances']),
  },
  beforeMount() {
    this.fetchOrgs()
        .then(this.getUserInstances)
        .then((response: any) => {
          this.user = {
            applicantOrgs: this.orgs.map((org: any) => ({
              orgId: org.id,
              orgName: org.name,
            })),
            email: this.getCurrentUser.email,
            instances: response.data,
          };
        })
        .catch((error: any) => handleApiError(error, this));
  },
};
