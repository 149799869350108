
import Vue from 'vue';
import handleApiError from "@/shared/apiErrorUtil";
import GrantApp from "@/sg_copy/model/GrantApp";
import {GrantAppDetailDto} from "@/sg_copy/swagger-generated";
import {mapActions} from "vuex";


export default Vue.extend({
  name: 'ChildAppDropdownMenu',

  props: {
    mainApp: {
      type: Object as () => GrantApp,
      required: true
    },
    childAppDetail: {
      type: Object as () => GrantAppDetailDto,
      required: true
    }
  },

  computed: {
    deleteAllowed(): boolean {
      return (
          this.childAppDetail &&
          this.childAppDetail.userPermission &&
          this.childAppDetail.userPermission.deleteAllowed
      );
    }
  },

  methods: {
    ...mapActions("mySubmissionStore", ["deleteApp"]),
    deleteChildAppClickHandler(
        mainApp: GrantApp,
        childAppDetail: GrantAppDetailDto
    ): void {
      if (childAppDetail.userPermission.deleteAllowed) {
        const h = this.$createElement;
        const messageVNode = h("div", [
          h("div", {
            domProps: {
              innerHTML:
                  "<b>Are you sure you want to delete Application No. " +
                  this.header(childAppDetail) +
                  "?</b></br>" +
                  "<i>The application will be permanently deleted. You can't undo this action.</i>"
            }
          })
        ]);

        this.$bvModal
            .msgBoxConfirm([messageVNode], {
              id: "deleteModal",
              title: "Delete Submission",
              okTitle: "Delete",
              okVariant: "danger",
              centered: true
            })
            .then(response => {
              if (response) {
                this.deleteChildApp(mainApp, childAppDetail);
              }
            });
      }
    },

    header(childApp: GrantAppDetailDto): string {
      return (
          childApp.application +
          (childApp.projectTitle ? " - " + childApp.projectTitle : "")
      );
    },

    emitChildAppDeletedEvent(): void {
      this.$emit('child-app-deleted');
    },

    deleteChildApp(mainApp: GrantApp, childApp: GrantAppDetailDto): void {
      mainApp.processing = true;
      this.deleteApp(childApp.applicationId)
          .then(() => {
            childApp.removed = true;
            mainApp.processing = false;
            this.emitChildAppDeletedEvent();
            const message = "Application " + mainApp.header + " has been deleted";
            this.$bvToast.toast(message, {
              title: "Success",
              variant: "success",
              solid: true
            });
          })
          .catch(error => {
            handleApiError(
                error,
                this,
                "Error deleting application " + mainApp.header
            );
          })
          .finally(() => (mainApp.processing = false));
    }
  }
});
