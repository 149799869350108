
import Vue from 'vue';
import { mapActions } from 'vuex';
import GrantApp from "@/sg_copy/model/GrantApp";
import { GrantAppDetailDto, UserDto } from "@/sg_copy/swagger-generated";
import ResponseCard from "@/sg_copy/components/ResponseCard.vue";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import Response from "@/sg_copy/model/Response";
import { apiErrorUtil } from "@/sg_copy/utils/ApiErrorUtil";

export default Vue.extend({
  name: 'AppDetailModal',

  components: {
    ResponseCard,
    ErrorMessageCard,
  },

  props: {
    app: {
      type: Object as () => GrantApp,
      required: true,
    },
  },

  data() {
    return {
      appDetail: null as GrantAppDetailDto | null,
      responses: [] as Response[],
      error: false as boolean,
      shares: [] as UserDto[],
      readMoreExpanded: false as boolean,
      localApp: this.app as GrantApp,
    };
  },

  computed: {
    appRemoved(): boolean {
      return this.localApp && this.localApp.isRemoved;
    },

    userString(): string {
      if (!this.appDetail?.shares) return '';

      return this.appDetail.shares.map((user, index) => {
        const isMe = user.id === this.localApp.user.id ? ' (me)' : '';
        const separator = index < this.appDetail.shares.length - 1 ? ', ' : '';
        return `${user.name}${isMe}${separator}`;
      }).join('');
    },
  },

  watch: {
    appRemoved: {
      handler() {
        this.closeModal();
      },
    },
    app: {
      immediate: true,
      handler(newApp: GrantApp) {
        this.localApp = newApp;
      }
    }
  },

  created() {
    if (this.localApp) {
      this.load();
    }
  },

  methods: {
    ...mapActions('mySubmissionStore', ['getApp']),

    closeModal() {
      this.$bvModal.hide("app-detail-modal");
    },

    emitCloseModal() {
      this.$emit('app-modal-closed');
    },

    async load() {
      this.error = false;
      this.shares = [];
      this.appDetail = null;
      this.responses = [];
      this.readMoreExpanded = false;

      try {
        const response = await this.getApp(this.localApp.id);
        this.appDetail = response.data;

        if (!this.appDetail) {
          this.error = true;
          return;
        }

        this.responses = this.appDetail.responses.map(
            appResponse => new Response(appResponse, this.localApp.user)
        );

        if (this.appDetail.shares && !this.appDetail.shareExpired) {
          this.shares = [...this.appDetail.shares].sort((a, b) => {
            if (a.id === this.localApp.user.id) return -1;
            if (b.id === this.localApp.user.id) return 1;
            return 0;
          });
        }
      } catch (error) {
        this.error = true;
        this.closeModal();
        apiErrorUtil.showApiError(
            this.localApp,
            "Unable to load application detail"
        );
      }
    },
  },
});
