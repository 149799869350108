
import Vue from 'vue';
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import { UserDto } from "@/sg_copy/swagger-generated";

const ORG_FILTER = "orgFilter";
const ROUND_FILTER = "roundFilter";
const STATUS_FILTER = "statusFilter";
const OWNED_BY_FILTER = "ownedByFilter";
const SHARED_WITH_FILTER = "sharedWithFilter";
const SUBMITTED_DATE_FILTER = "submittedDateFilter";
const DUE_DATE_FILTER = "dueDateFilter";
const FORM_NAME_FILTER = "formNameFilter";
const RESPONSE_FILTER = "responseFilter";
const INSTANCE_FILTER = "instanceFilter";
const CLASSIE_ORG_TYPE_FILTER = "classieOrgTypeFilter";
const REGION_FILTER = "regionFilter";

export default Vue.extend({
  name: 'FilterTags',

  props: {
    filter: {
      type: Object as () => SubmissionFilter,
      required: true
    },
    user: {
      type: Object as () => UserDto,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    },
    classieOrgTypeOptions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filterTags: [] as Array<{ value: string; text: string }>,
      localFilter: null as SubmissionFilter | null

    }
  },
  watch: {
    filter: {
      immediate: true,
      handler(newFilter: SubmissionFilter) {
        if (newFilter) {
          this.localFilter = newFilter;
        }
      },
    },
    'localFilter.showSummarisedSearch': {
      handler() {
        if (this.localFilter.showSummarisedSearch) {
          this.getFilterTags();
        }
      }
    }
  },

  mounted() {
    this.getFilterTags();
  },

  methods: {
    removeFilterTags(tag: { value: string }) {
      if (tag.value === ORG_FILTER) {
        this.localFilter.orgSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== ORG_FILTER);
      }

      if (tag.value === ROUND_FILTER) {
        this.localFilter.roundSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== ROUND_FILTER);
      }

      if (tag.value === STATUS_FILTER) {
        this.localFilter.statusSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== STATUS_FILTER);
      }

      if (tag.value === OWNED_BY_FILTER) {
        this.localFilter.ownedBySelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== OWNED_BY_FILTER);
      }

      if (tag.value === SHARED_WITH_FILTER) {
        this.localFilter.sharedWithSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== SHARED_WITH_FILTER);
      }

      if (tag.value === SUBMITTED_DATE_FILTER) {
        this.localFilter.submittedDateTo = undefined;
        this.localFilter.submittedDateFrom = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== SUBMITTED_DATE_FILTER);
      }

      if (tag.value === DUE_DATE_FILTER) {
        this.localFilter.dueDateFrom = undefined;
        this.localFilter.dueDateTo = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== DUE_DATE_FILTER);
      }

      if (tag.value === FORM_NAME_FILTER) {
        this.localFilter.formNameSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== FORM_NAME_FILTER);
      }

      if (tag.value === RESPONSE_FILTER) {
        this.localFilter.responseSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== RESPONSE_FILTER);
      }

      if (tag.value === INSTANCE_FILTER) {
        this.localFilter.instanceSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== INSTANCE_FILTER);
      }

      if (tag.value === CLASSIE_ORG_TYPE_FILTER) {
        this.localFilter.classieOrgIdSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== CLASSIE_ORG_TYPE_FILTER);
      }

      if (tag.value === REGION_FILTER) {
        this.localFilter.regionSelected = undefined;
        this.filterTags = this.filterTags.filter(f => f.value !== REGION_FILTER);
      }

      if (this.filterTags.length === 0) {
        this.displaySimpleSearch();
      }

      this.saveFilterRefresh();
    },

    getFilterTags() {
      //clear existing filter
      this.filterTags.splice(0);

      if (this.localFilter.orgSelected != null) {
        this.filterTags.push({
          value: ORG_FILTER,
          text: "Organisation: " +
              this.user.applicantOrgs.find(
                  org => org.orgId === this.localFilter.orgSelected
              ).orgName,
        });
      }
      if (this.isNotEmpty(this.localFilter.roundSelected)) {
        this.filterTags.push({
          value: ROUND_FILTER,
          text: "Round: " + this.localFilter.roundSelected,
        });
      }
      if (this.localFilter.statusSelected != null) {
        this.filterTags.push({
          value: STATUS_FILTER,
          text: "Status: " +
              this.statusOptions.find(r => r.value === this.localFilter.statusSelected)
                  .text,
        });
      }
      if (this.isNotEmpty(this.localFilter.ownedBySelected)) {
        this.filterTags.push({
          value: OWNED_BY_FILTER,
          text: "Owned by: " + this.localFilter.ownedBySelected,
        });
      }
      if (this.isNotEmpty(this.localFilter.sharedWithSelected)) {
        this.filterTags.push({
          value: SHARED_WITH_FILTER,
          text: "Shared with: " + this.localFilter.sharedWithSelected,
        });
      }
      if (
          this.isNotEmpty(this.localFilter.submittedDateTo) ||
          this.isNotEmpty(this.localFilter.submittedDateFrom)
      ) {
        let str =
            "Submitted date: " +
            this.getDateRangeString(
                this.localFilter.submittedDateFrom,
                this.localFilter.submittedDateTo
            );
        this.filterTags.push({ value: SUBMITTED_DATE_FILTER, text: str });
      }
      if (
          this.isNotEmpty(this.localFilter.dueDateTo) ||
          this.isNotEmpty(this.localFilter.dueDateFrom)
      ) {
        let str =
            "Due date: " +
            this.getDateRangeString(this.localFilter.dueDateFrom, this.localFilter.dueDateTo);
        this.filterTags.push({ value: DUE_DATE_FILTER, text: str });
      }
      if (this.isNotEmpty(this.localFilter.formNameSelected)) {
        this.filterTags.push({
          value: FORM_NAME_FILTER,
          text: "Form name: " + this.localFilter.formNameSelected,
        });
      }
      if (this.isNotEmpty(this.localFilter.responseSelected)) {
        this.filterTags.push({
          value: RESPONSE_FILTER,
          text: "Response text: " + this.localFilter.responseSelected,
        });
      }
      if (this.isNotEmpty(this.localFilter.instanceSelected)) {
        this.filterTags.push({
          value: INSTANCE_FILTER,
          text: "Funder: " +
              this.user.instances.find(
                  i => i.instanceId === this.localFilter.instanceSelected
              ).instanceName,
        });
      }
      if (this.localFilter.classieOrgIdSelected != null) {
        this.filterTags.push({
          value: CLASSIE_ORG_TYPE_FILTER,
          text: "Type of Funder: " +
              this.classieOrgTypeOptions.find(
                  r => r.id === this.localFilter.classieOrgIdSelected
              ).name,
        });
      }
      if (this.localFilter.regionSelected != null) {
        this.filterTags.push({
          value: REGION_FILTER,
          text: "Region: " + this.localFilter.regionSelected,
        });
      }
    },

    getDateRangeString(from: string, to: string): string {
      let str = "";
      if (from === null || from === "") {
        str += "any to ";
      } else {
        str += from + " to ";
      }
      if (to === null || to === "") {
        str += "any";
      } else {
        str += to;
      }
      return str;
    },

    isNotEmpty(str: string | null): boolean {
      return str != null && str !== "";
    },

    displaySimpleSearch() {
      this.filterTags = [];
      this.$emit('display-simple-search', this.localFilter);
    },

    saveFilterRefresh() {
      this.$emit('filter-tags-refresh', this.localFilter);
    }
  },
});
