
import {
  GrantAppDto,
  GrantAppTinyDto,
  SubmissionListDto,
  UserDto,
} from "@/sg_copy/swagger-generated";
import InfiniteLoading, {StateChanger} from "vue-infinite-loading";
import AppSubmissionCard from "@/sg_copy/components/AppSubmissionCard.vue";
import GrantApp from "@/sg_copy/model/GrantApp";
import AppDetailModal from "@/sg_copy/components/AppDetailModal.vue";
import ChangeUserModal from "@/sg_copy/components/ChangeUserModal.vue";
import ShareApplicationModal from "@/sg_copy/components/ShareApplicationModal.vue";
import handleApiError from "@/shared/apiErrorUtil";
import AssignOrgModal from "@/sg_copy/components/AssignOrgModal.vue";
import {AppDropdownAction} from "@/sg_copy/model/AppDropdownAction";
import SubmissionsSearch from "@/sg_copy/components/SubmissionsSearch.vue";
import SubmissionFilter from "@/sg_copy/model/SubmissionFilter";
import CreateVariationModal from "@/sg_copy/components/CreateVariationModal.vue";
import {mapActions} from "vuex";

export default {
  components: {
    CreateVariationModal,
    SubmissionsSearch,
    InfiniteLoading,
    AppSubmissionCard,
    AppDetailModal,
    ChangeUserModal,
    ShareApplicationModal,
    AssignOrgModal,
  },
  props: {
    appIdPath: String,
    archived: Boolean,
    user: Object as () => UserDto,
  },
  data() {
    return {
      submissions: [] as Array<GrantApp>,
      result: null as SubmissionListDto,
      infiniteId: 0,
      infiniteState: null as StateChanger,
      displayedModal: null as GrantApp,
      variationModalApp: null as GrantAppTinyDto,
      showSubmissionModal: false,
      grantAppIdFocus: null as number | null,
      filter: new SubmissionFilter(),
    };
  },
  methods: {
    ...mapActions("mySubmissionStore", [
      "getSubmissions",
      "getAppSummary",
      "removeOrg",
    ]),
    ...mapActions("auth", ["getToken"]),
    onFilterChange(filter: SubmissionFilter) {
      this.grantAppIdFocus = null;
      this.filter = filter;
      this.refresh();
    },
    refresh() {
      this.submissions.length = 0;
      this.result = null;
      this.infiniteId++;
    },
    appModalClosed() {
      this.grantAppIdFocus = null;
      this.showSubmissionModal = false;
    },
    updateApp() {
      if (this.displayedModal) {
        this.displayedModal.processing = true;
        this.getAppSummary(this.displayedModal.id)
          .then(response => {
            this.displayedModal.app = response.data;
            this.changeFocusedApp(this.displayedModal.id);
          })
          .catch(error => handleApiError(error, this))
          .finally(() => {
            if (this.displayedModal) {
              this.displayedModal.processing = false;
            }
          });
      }
    },
    showModal(app: GrantApp): void {
      this.displayedModal = app;
      this.showSubmissionModal = true;
      this.changeFocusedApp(app.id);
      this.$nextTick(() => {
        this.$bvModal.show("app-detail-modal");
      });
    },
    showVariationModal(app: GrantAppTinyDto): void {
      this.variationModalApp = app;
      this.$bvModal.show("create-variation-modal");
    },
    handleDropDownAction(app: GrantApp, action: AppDropdownAction): void {
      this.displayedModal = app;
      this.$nextTick(() => {
        switch (action) {
          case "change":
            this.$bvModal.show("change-user-modal");
            break;
          case "share":
            this.$bvModal.show("share-application-modal");
            break;
          case "assignOrg":
            this.$bvModal.show("assign-org-modal");
            break;
          case "removeOrg":
            this.removeOrgFromSubmission(app);
            break;
        }
      });
    },
    async removeOrgFromSubmission(app: GrantApp) {
      try {
        await this.removeOrg(app.app.applicationId);
        try {
          const response = await this.getAppSummary({
            appId: app.app.applicationId,
          });
          this.displayedModal.app = response.data;
          this.grantAppIdFocus = app.app.applicationId;
        } catch (error) {
          app.remove();
          this.grantAppIdFocus = null;
        }
        this.$bvToast.toast("Org removed", {
          title: "Success",
          variant: "success",
        });
      } catch (error) {
        handleApiError(error, this, "Failed to remove Org from Submission");
      }
    },
    changeFocusedApp(appId: number): void {
      this.grantAppIdFocus = appId;
    },
    loadMore() {
      if ((this.result && this.result.nextStartIndex !== -1) || !this.result) {
        this.getSubmissions({
          result: this.result,
          filter: this.filter,
          archived: this.archived,
        })
          .then(response => {
            this.result = response.data;
            for (let ga of this.result.data) {
              const gav = new GrantApp(ga, this.user);
              this.submissions.push(gav);
              if (this.isAppInFocus(ga)) {
                this.$nextTick(() => this.scrollToApp(gav));
              }
            }
            if (this.infiniteState) {
              if (this.result.nextStartIndex === -1) {
                this.infiniteState.complete();
              } else {
                this.infiniteState.loaded();
              }
            }
            if (this.shouldLoadMore()) {
              this.loadMore();
            }
          })
          .catch(error => {
            if (this.infiniteState) {
              this.infiniteState.complete();
            }
            handleApiError(error, this);
          });
      }
    },
    isAppInFocus(ga: GrantAppDto): boolean {
      const isAppOnPath =
        this.appIdPath && this.appIdPath === ga.applicationId.toString();
      const isAppInFocus = ga.applicationId === this.grantAppIdFocus;
      return isAppOnPath || isAppInFocus;
    },
    scrollToApp(gav: GrantApp): void {
      if (document) {
        const element = document.getElementById(gav.app.applicationId.toString());
        if (element) {
          element.scrollIntoView(true);
        }
      }
      if (this.appIdPath || this.showSubmissionModal) {
        this.showModal(gav);
      } else {
        gav.toggleExpanded(true);
      }
    },
    shouldLoadMore(): boolean {
      return (
        (this.appIdPath || this.grantAppIdFocus) &&
        !this.displayedModal &&
        this.result &&
        this.result.nextStartIndex !== -1
      );
    },
    infiniteHandler($state) {
      this.infiniteState = $state;
      this.loadMore();
    },
  },
};
