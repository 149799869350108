
import {defineComponent} from 'vue';
import { mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import ErrorMessageCard from '@/sg_copy/components/ErrorMessageCard.vue';
import handleApiError from '@/shared/apiErrorUtil';
import { permissionService } from '@/sg_copy/service/PermissionService';
import GrantApp from '@/sg_copy/model/GrantApp';
import {
  ApplicantOrgMemberDto,
  GrantAppDetailDto,
  SfPermission,
  ShareApplicationDto,
  SimpleIdDto,
  UserDto,
} from '@/sg_copy/swagger-generated';

const MAX_USER = 100;

export default defineComponent({
  name: 'ShareApplicationModal',

  components: {
    ErrorMessageCard,
    Multiselect,
  },

  props: {
    app: {
      type: Object as () => GrantApp,
      required: true,
    },
  },

  data() {
    return {
      localApp: null as GrantApp | null,
      appDetail: null as GrantAppDetailDto | null,
      allUsers: [] as Array<ApplicantOrgMemberDto>,
      allUserMap: new Map<number, ApplicantOrgMemberDto>(),
      selectedUsers: [] as Array<UserDto>,
      selectedUserMap: new Map<number, UserDto>(),
      error: false,
      usernameInput: [] as Array<any>,
      usernameInputOptions: [] as Array<any>,
      loading: true,
      validationUser: null as string | null,
      validationExpiry: null as string | null,
      expiryDate: '',
      shareNote: '',
      existing: false,
      ALL_PERMISSIONS: [
        SfPermission.GRANT_APP_CREATE,
        SfPermission.GRANT_APP_WRITE,
        SfPermission.GRANT_APP_READ,
        SfPermission.GRANT_APP_SUBMIT,
      ],
      selectedPermissions: [] as Array<SfPermission>,
    };
  },

  watch: {
    app: {
      immediate: true,
      handler(newApp: GrantApp) {
        if (newApp) {
          this.localApp = new GrantApp(newApp.app, newApp.user);
        }
      },
    },
    selectedUsers: {
      handler() {
        if (this.selectedUsers.length > 0 && this.selectedUsers.length < MAX_USER) {
          this.validationUser = null;
        }
      },
    },
    expiryDate: {
      handler(val: string) {
        if (val && val.length > 0) {
          this.validationExpiry = null;
        }
      },
    },
    selectedPermissions: {
      handler() {
        this.usernameInput = [];
        this.updateOptionFromList();
      },
    },
  },

  computed: {
    permissionOptions() {
      return this.ALL_PERMISSIONS.map(permission => ({
        value: permission,
        text: permissionService.getDescriptionFor(permission),
      }));
    },

    isProcessing: {
      get(): boolean {
        return this.localApp?.processing || false;
      },
      set(value: boolean) {
        if (this.localApp) {
          this.localApp.processing = value;
          this.$emit('update:app', { ...this.localApp });
        }
      },
    },
  },

  created() {
    if (this.app) {
      this.load();
    }
  },

  methods: {
    ...mapActions('mySubmissionStore', ['getApp', 'getMembers', 'shareApp']),

    removeUser(tag: UserDto) {
      this.selectedUsers = this.selectedUsers.filter(e => e.id !== tag.id);
      this.selectedUserMap.delete(tag.id);
    },

    addUsers() {
      if (!this.usernameInput) {
        return;
      }

      if (this.usernameInput.length > 0 && this.selectedUsers.length >= MAX_USER) {
        this.validationUser = 'Maximum users reached';
      }

      for (const username of this.usernameInput) {
        const user = this.allUserMap.get(Number.parseInt(username.id));
        if (
            user &&
            !this.selectedUserMap.get(user.user.id) &&
            this.selectedUsers.length < MAX_USER
        ) {
          this.selectedUsers.push(user.user);
          this.selectedUserMap.set(user.user.id, user.user);
          this.validationUser = null;
        }
      }
      this.usernameInput = [];
    },

    async load() {
      this.loading = true;
      this.resetState();

      try {
        const [appResponse, membersResponse] = await Promise.all([
          this.getApp(this.localApp?.id),
          this.getMembers({ appId: this.localApp?.id, operation: 'share' }),
        ]);

        this.handleAppResponse(appResponse.data);
        this.handleMembersResponse(membersResponse.data.data);

        if (!this.appDetail || !this.allUsers) {
          throw new Error('Unable to load application detail');
        }

        this.loading = false;
        this.usernameInput = null;
        this.updateOptionFromList();
      } catch (error) {
        this.error = true;
        this.$bvModal.hide('share-application-modal');
        handleApiError(
            'Unable to load application detail',
            this,
            'Unable to load application detail'
        );
      }
    },

    resetState() {
      this.usernameInput = [];
      this.selectedUsers = [];
      this.selectedUserMap.clear();
      this.allUserMap.clear();
      this.error = false;
      this.appDetail = null;
      this.validationUser = null;
      this.validationExpiry = null;
      this.expiryDate = null;
      this.shareNote = '';
      this.existing = false;
      this.selectedPermissions = [...this.ALL_PERMISSIONS];
    },

    handleAppResponse(appDetail: GrantAppDetailDto) {
      this.appDetail = appDetail;
      if (this.appDetail.shareExpiry) {
        this.expiryDate = moment(this.appDetail.shareExpiry).format('YYYY-MM-DD');
        this.existing = true;
      }

      this.selectedUserMap.clear();
      this.selectedUsers = [];
      if (this.appDetail.shares) {
        for (const share of this.appDetail.shares) {
          this.selectedUsers.push(share);
          this.selectedUserMap.set(share.id, share);
          this.existing = true;
        }
      }
      this.shareNote = this.appDetail.shareNote || '';
    },

    handleMembersResponse(members: Array<ApplicantOrgMemberDto>) {
      this.allUsers = members;
      for (const user of this.allUsers) {
        this.allUserMap.set(user.user.id, user);
      }
    },

    updateOptionFromList() {
      if (!this.allUsers) {
        return;
      }

      const permissions = this.selectedPermissions.length > 0
          ? this.selectedPermissions
          : this.ALL_PERMISSIONS;

      this.usernameInputOptions = this.allUsers
          .filter(member => permissionService.anyMatch(member.permissions, permissions))
          .map(member => ({
            text: member.user.name,
            id: member.user.id.toString(),
          }));
    },

    shareUser(bvModalEvt: Event) {
      this.addUsers();
      this.validationUser = null;
      this.validationExpiry = null;

      bvModalEvt.preventDefault();
      this.isProcessing = true;

      if (!this.existing && this.selectedUsers.length === 0) {
        this.validationUser = 'Please select at least one user.';
      }

      if (this.expiryDate) {
        this.validateExpiryDate();
      }

      if (this.validationExpiry || this.validationUser) {
        this.isProcessing = false;
      } else {
        this.callShareAppApi();
      }
    },

    validateExpiryDate() {
      if (!moment(this.expiryDate, 'YYYY-MM-DD', true).isValid()) {
        this.validationExpiry = 'Invalid date.';
        return;
      }

      if (!moment(this.expiryDate, 'YYYY-MM-DD', true).isAfter(moment().format('YYYY-MM-DD'))) {
        this.validationExpiry = 'Please enter a date in the future.';
        return;
      }

      if (moment(this.expiryDate, 'YYYY-MM-DD', true).isAfter(moment().add(25, 'year'))) {
        this.validationExpiry = 'Maximum shared expiry is 25 years.';
      }
    },

    async callShareAppApi() {
      const users: Array<SimpleIdDto> = this.selectedUsers.map(user => ({ id: user.id }));
      const shareDto: ShareApplicationDto = {
        expiryDate: this.expiryDate,
        shareNote: this.shareNote,
        users,
      };

      try {
        await this.shareApp({
          appId: this.localApp?.id,
          shareDto,
        });

        this.isProcessing = false;
        this.$emit('user-shared');
        this.$bvModal.hide('share-application-modal');
        this.showSuccessToast();
      } catch (error) {
        this.isProcessing = false;
        this.error = true;
        handleApiError(
            error,
            this,
            `Error sharing application ${this.localApp?.header}`
        );
      }
    },

    showSuccessToast() {
      const message = `Application ${this.localApp?.header}${
          this.selectedUsers.length === 0
              ? ' is no longer shared with other user/s.'
              : ` is now shared with ${this.selectedUsers.length} users ${
                  this.expiryDate
                      ? ` until ${moment(this.expiryDate).format('DD MMM YYYY')}`
                      : 'without expiry date'
              }.`
      }`;

      this.$bvToast.toast(message, {
        title: 'Success',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
      });
    },
  },
});
