
import Vue from 'vue';
import { mapActions } from 'vuex';
import NestedForms from '@/sg_copy/components/NestedForms.vue';
import GrantApp from '@/sg_copy/model/GrantApp';
import Response from '@/sg_copy/model/Response';
import ResponseSummaryCard from '@/sg_copy/components/ResponseSummaryCard.vue';
import { apiErrorUtil } from '@/sg_copy/utils/ApiErrorUtil';
import { GrantAppDetailDto, GrantAppTinyDto } from '@/sg_copy/swagger-generated';
import ErrorMessageCard from '@/sg_copy/components/ErrorMessageCard.vue';
import { canDelete } from '@/sg_copy/model/GrantAppDetail';

export default Vue.extend({
  name: 'AppSubmissionExpandedCard',

  components: {
    ResponseSummaryCard,
    ErrorMessageCard,
    NestedForms,
  },

  props: {
    app: {
      type: Object as () => GrantApp,
      required: true,
    },
  },

  data() {
    return {
      localApp: null as GrantApp | null,
      appDetail: null as GrantAppDetailDto | null,
      responses: [] as Array<Response>,
      error: false as boolean,
    };
  },

  watch: {
    app: {
      immediate: true,
      handler(newApp: GrantApp) {
        if (newApp) {
          this.localApp = new GrantApp(newApp.app, newApp.user);
        }
      }
    }
  },

  computed: {
    hasNestedForms(): boolean {
      return (this.appDetail?.childForms?.length ?? 0) > 0;
    },
  },

  created() {
    if (this.localApp) {
      this.load();
    }
  },

  methods: {
    ...mapActions('mySubmissionStore', ['getApp']),

    emitOpenResponse(): void {
      this.$emit('open-response');
    },

    childAppDeleteHandler(
        parentAppDetailDto: GrantAppDetailDto
    ): void {
      if (canDelete(parentAppDetailDto) && this.localApp) {
        this.localApp.app.userPermission.deleteAllowed = true;
      }
    },

    async load(): Promise<void> {
      if (!this.localApp) return;

      this.appDetail = null;
      this.responses.length = 0;
      this.error = false;

      try {
        const response = await this.getApp(this.localApp.id);
        this.appDetail = response.data;

        if (this.appDetail) {
          for (const response of this.appDetail.responses) {
            this.responses.push(new Response(response, this.localApp.user));
          }

          // Update user permissions of the local app with newly fetched data
          // because deleting child applications will change ability to delete the main application
          this.localApp.app.userPermission = this.appDetail.userPermission;
        } else {
          this.error = true;
          apiErrorUtil.showApiError(
              this.localApp,
              'Unable to load application detail'
          );
        }
      } catch {
        this.error = true;
        apiErrorUtil.showApiError(
            this.localApp,
            'Unable to load application detail'
        );
      }
    },

    emitShowVariationModal(): void {
      this.$emit('show-variation-modal');
    },

    showVariationModal(app: GrantAppTinyDto): void {
      this.$emit('show-variation-modal', app);
    },
  },
});
